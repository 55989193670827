// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bettercustomer-js": () => import("./../../../src/pages/bettercustomer.js" /* webpackChunkName: "component---src-pages-bettercustomer-js" */),
  "component---src-pages-betterseller-js": () => import("./../../../src/pages/betterseller.js" /* webpackChunkName: "component---src-pages-betterseller-js" */),
  "component---src-pages-constructioncustlanding-js": () => import("./../../../src/pages/constructioncustlanding.js" /* webpackChunkName: "component---src-pages-constructioncustlanding-js" */),
  "component---src-pages-constructionselllanding-js": () => import("./../../../src/pages/constructionselllanding.js" /* webpackChunkName: "component---src-pages-constructionselllanding-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custlanding-js": () => import("./../../../src/pages/custlanding.js" /* webpackChunkName: "component---src-pages-custlanding-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-seller-js": () => import("./../../../src/pages/seller.js" /* webpackChunkName: "component---src-pages-seller-js" */),
  "component---src-pages-selllanding-js": () => import("./../../../src/pages/selllanding.js" /* webpackChunkName: "component---src-pages-selllanding-js" */)
}

