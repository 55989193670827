/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/global.css';

// Activates Google Optimize experiments
// Disabled and moved back to layout (campaign layout inititially)
// const activateOptimize = () => {
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({ event: "optimize.activate" });
// };
// export const onRouteUpdate = () => {
//   // ...
//   activateOptimize();
// };